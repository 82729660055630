<template>
    <div class="rightLink">
      <div class="bottomDiv">
        <div class="title">设备管理</div>
        <div class="formDiv">
          <el-form label-position="right" label-width="80px">
            <el-row :gutter="20">
            
              <el-col :span="8">
                <el-form-item label="设备编号:">
                  <el-input
                    v-model="FormData.device_id"
                    placeholder="请输入设备编号"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="车型:">
                  <el-select v-model="FormData.type_id" placeholder="请选择充电类型">
                    <el-option v-for="car in carList" :key="car.value" :value="car.value" :label="car.label"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="1">
                <div class="BtnSearch" @click="searchBtn()">查询</div>
              </el-col>
            </el-row>
          </el-form>
          
        </div>
        <!-- 表格部分 -->
        <div class="tableDiv">
        <el-table
        ref="multipleTableRef"
          :data="tableData"
          border 
          v-loading="loading"
          height="480px"
          style="
            width: 90%;
            margin: 20px auto;
            
            overflow-y: auto;
          "
        >
            <el-table-column label="设备编号" prop="device_id" />
            <el-table-column label="站点名称" prop="station_name" width="150" />
            <el-table-column label="设备类型" prop="type_id">
              <template #default="scope">
                <span v-if="scope.row.type_id == 2">电动车</span>
                <span v-if="scope.row.type_id == 4">电动汽车</span>
              </template>
            </el-table-column>

            <el-table-column label="功率" prop="wx_id" />
            <el-table-column label="状态" prop="state" />
            <el-table-column
              label="操作"
              width="220"
              align="center"
            > 
              <template #default="scope">
                <!-- <div class="BtnSearch1 BtnSearch" @click="openCharg(scope.$index, scope.row)" >
                  开启充电
                </div>
                <div class="BtnSearch1 BtnSearch" @click="offCharg(scope.$index, scope.row)" >
                  关闭充电
                </div> -->
                <div
                  class="BtnSearch1 BtnSearch"
                  style="width:100px"
                  @click="binduser(scope.$index, scope.row)"
                  >绑定运营商</div
                >
                <div
                  class="BtnSearch1 BtnSearch"
                  @click="delcharg(scope.$index, scope.row)"
                  >删除</div
                >
                
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
          <el-pagination
              :current-page="FormData.page"
              :page-size="pageSize"
              :small="small"
              layout="total, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
        </div>
        </div>
  
  <!-- 编辑 -->
          <el-dialog
            v-model="Dialog.editLevy"
            title="编辑"
            width="820px"
            :before-close="closeDialog"
            class="dialog"
          >
            <div class="formDiv">
              <el-form
            ref="FormRules"
            :rules="rules"
            :model="FormData"
            label-position="right"
            label-width="100px"
          >
            <el-row :gutter="20">
              <el-col :span="9">
                <!-- <el-form-item label="id:">
                  <el-input
                    v-model="Dialog.DialogData.id"
                    placeholder="请输入id"
                  ></el-input>
                </el-form-item> -->
                <el-form-item label="设备编号:">
                  <el-input
                    v-model="Dialog.DialogData.device_id"
                    placeholder="请输入设备编号"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="站点名称:">
                  <el-input
                    v-model="Dialog.DialogData.station_name"
                    placeholder="请输入站点名称"
                  ></el-input>
                </el-form-item>
               
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="9">
                  <el-form-item label="选择省份:">
                    <el-select v-model="Dialog.DialogData.province" placeholder="请选择省" @change="handleProvinceChange">
                    <el-option v-for="provinces in provinceList" :key="provinces.value" :value="provinces.label" :label="provinces.label"/>
                    <!-- </el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="选择市:">
                  <el-select v-model="Dialog.DialogData.city" placeholder="请选择市"  @change="handleCityChange">
                  <!-- <el-option value="">请选择</el-option> -->
                    <el-option v-for="citys in cityList[0]" :key="citys.value" :value="citys.label">{{ citys.label }}</el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="9">
                <el-form-item label="选择区/县:">
                  <el-select v-model="Dialog.DialogData.county" placeholder="请选择区/县" >
                  <!-- <el-option value="">请选择</el-option> -->
                    <el-option v-for="countys in countyList[0]" :key="countys.value" :value="countys.label">{{ countys.label }}</el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="小区名称:">
                  <el-input
                    v-model="Dialog.DialogData.community"
                    placeholder="请输入小区名称"
                  ></el-input>
                </el-form-item>
                
              </el-col>
            </el-row>
        
           
          </el-form>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button class="Btn" @click="closeDialog">取消</el-button>
                <el-button class="Btn" @click="submitDialog">提交</el-button>
              </span>
            </template>
        
          </el-dialog>
    
      </div>
    </div>
  </template>
  <script>
  // import { ref } from 'vue'
  import { onMounted, reactive, toRefs } from "vue-demi";
  import { sel_device } from "@/request/api";
  import { ElMessage } from "element-plus";
  import ProvinceCityCountry from '../../assets/json/address'
  import {  regionData } from 'element-china-area-data'
  export default {
    name: "EditArea",
    setup() {
      const data = reactive({
        //表单数据
        FormData: {
          page:1
        },
        tableData: [
        {
            serial_number:"YH001",
            user_name:"张三"
          },
        ],
        carList:[
            {
              value:2,
              label:'电动车'
            },
            {
              value:4,
              label:'电动汽车'
            }
          ],
          car:'',
        provinceData: ProvinceCityCountry.address,
        // options: regionData,
        // selectedOptions: "",
        // 省市县
        regionData,
        provinceList: [],
        cityList: [],
        countyList: [],
        province: '',
        city: '',
        county: '',
        
        //   弹框
        Dialog: {
          editLevy: false,
          DialogData: {},
        },
      
        loading:false,
        //   分页
        currentPage:1,
        pageSize:20,
        total:400,
        checked:0,
        small:true,
  
   

     
      });
      const searchBtn = () => {
        getList()
      };
      
      const binduser = () => {
        console.log('绑定运营商');
      }
      const delcharg = () => {
        console.log('删除');
      }

  
      const closeDialog = () => {
        data.Dialog.editLevy = false;
      };
      const closeDialog1 = () => {
        data.Dialog1.editLevy = false;
      };
  

  
     
      const submitData = () => {
     
      };
      
      const getList = ()=>{
        const dataa = data.FormData
        data.loading = true
        sel_device(dataa).then((res)=>{
          console.log(res);
          if (res) {
            console.log(res,'设备列表');
            data.loading = false
            if (res.code == 200) {
              data.tableData = res.data
              data.total = data.tableData.length
            } else {
              ElMessage.error(res.msg);
            }
          } else {
            ElMessage.error("数据获取失败");
          }
        })
      }
  
  
  
  
  
      const handleChange = (value) => {
        console.log(value,'value')
      }
  
      const handleSizeChange = (val) => {
        data.pageSize = val
      }
      const handleCurrentChange = (val) => {
        data.FormData.page = val;
        getList()
        // 
      }
  
  
  
 
  
      
  
      onMounted(() => {
        console.log(data);
        getList();
      });
      return {
        ...toRefs(data),
        searchBtn,
        closeDialog,
        closeDialog1,
        submitData,
        getList,
        binduser,
        delcharg,
        handleChange,
        handleSizeChange,
        handleCurrentChange,
      };
    },
  };
  </script>
  <style scoped>
  .formDiv {
    margin-top: 10px;
    height: 120px;
  }
  .bottomDiv {
      height: 100%;
    }
  
  .btn:focus {
    background-color: #03beb0;
    border-color: #03beb0;
  }
  
  .BtnSearch1{
    float: left;
    width: 80px;
    margin: 0 4px;
  }
  
  .pagination{
      display: flex;
      justify-content: space-between;
      padding: 0 68px;
      margin-top: -10px;
  }
  </style>
  